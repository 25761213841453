<template>
  <div class="from_outer">
    <div>
      <el-form ref="formName" label-width="120px" :model="formLabelAlign">
        <el-form-item
          label="名称："
          prop="name"
          :rules="[{ required: true, message: '请输入名称' }]"
        >
          <el-input
            placeholder="请输入名称"
            size="mini"
            class="form-inp"
            v-model="formLabelAlign.name"
          ></el-input>
        </el-form-item>

        <el-form-item label="角色权限：">


          
          <el-tree
            :check-strictly="strictly"
            ref="tree"
            :data="power_Data"
            show-checkbox
            node-key="id"
            :props="{
              children: 'item',
              label: 'name',
            }"
          >
          </el-tree>
        </el-form-item>

        <!-- <el-form-item
          label="是否启用："
          prop="is_enable"
          :rules="[{ required: true, message: '请选择是否启用' }]"
        >
          <el-radio
            v-for="item in enable_list"
            v-model="formLabelAlign.is_enable"
            :key="item.id"
            :label="item.id"
            >{{ item.name }}</el-radio
          >
        </el-form-item> -->
      </el-form>
      <div style="text-align: center; margin: 20px 0">
        <el-button @click="on_preserve('formName')" type="primary">{{
          type_name
        }}</el-button>
        <el-button @click="route_go">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { rolesaveInfo, rolesave } from "../../assets/request/api";
export default {
  name: "user_redact",

  data() {
    return {
      strictly: true,
      type_name: "保存",
      power_Data: [],
      enable_list: [],
      //要修改的id
      id: "",
      formLabelAlign: {
        name: "",
        is_enable: "",
      },
    };
  },
  props: {
    dialog_show: {
      type: Boolean,
    },
  },
  methods: {
    way_show(id) {
      let froms = {};
      if (id) froms.id = id;
      rolesaveInfo(froms).then((res) => {
        let { info, power, enable_list } = res.data;
        this.enable_list = enable_list;
        this.power_Data = power;
        if (id) {
          this.id = id;
          this.type_name = "修改";
          let { formLabelAlign } = this;
          let { name, items, is_enable } = info;
          this.$refs.tree.setCheckedKeys(items);
          formLabelAlign.name = name;
          formLabelAlign.is_enable = is_enable;
          this.strictly = false;
        } else {
          this.strictly = false;
        }
      });
    },
    //获取树节点选中的
    way_tree() {
      let parentArr = this.$refs.tree.getHalfCheckedKeys(); //通过 key 获取 半选中
      let childeArr = this.$refs.tree.getCheckedKeys(); //通过 key 获取 选中的
      let roleIds = childeArr.concat(parentArr); //全部选中的
      console.log(roleIds);
      return roleIds.join(",");
    },
    //点击保存
    on_preserve(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let { formLabelAlign, id } = this;
          if (id) {
            formLabelAlign.id = id;
          }
          formLabelAlign.power_id = this.way_tree();
          if (!formLabelAlign.power_id) {
            this.$message({
              message: "请给当前角色配置权限",
              type: "warning",
            });
            return;
          }
          rolesave(formLabelAlign).then((res) => {
            if (res.code == 0) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              let that = this;
              that.$emit("way_tableData");
              setTimeout(function () {
                that.route_go();
              }, 1000);
            }
          });
        } else {
          return false;
        }
      });
    },
    //返回
    route_go() {
      this.$emit("update:refbool", false);
    },
  },
};
</script>

<style lang="less" scoped>
.from {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}
.form-inp {
  width: 300px;
}
.pop-inp {
  width: 150px;
}
.tag-sty {
  width: 300px;
  display: flex;
  flex-direction: column;
}
.selectlength {
  color: #515a6e;
  border: 1px solid #abdcff;
  background-color: #f0faff;
  padding: 10px 20px;
  font-size: 14px;
  margin: 20px 0;
  border: 5px;
  span {
    color: rgb(45, 140, 240);
  }
}
.search_button {
  display: flex;
  margin-bottom: 18px;
  flex-direction: column-reverse;
}
.botton-an {
  text-align: center;
  margin-top: 10px;
}
.pop-img {
  margin: 0 5px 10px 0;
  width: 40px;
  height: 40px;
}
</style>